import React from 'react';

import {NumericFormat} from 'react-number-format';

import PropTypes from 'prop-types';

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
  props,
  ref,
) {
  const {onChange, maxnumber, ...other} = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: Number(values.value),
          },
        });
      }}
      isAllowed={(values) => {
        if (typeof maxnumber !== 'number') return true;

        const {floatValue} = values;

        return floatValue >= 0 && floatValue <= maxnumber;
      }}
      thousandSeparator='.'
      decimalSeparator=','
      decimalScale={3}
      allowNegative={false}
      valueIsNumericString
    />
  );
});

NumericFormatCustom.propTypes = {
  name: PropTypes.string,
  maxnumber: PropTypes.number,
  onChange: PropTypes.func,
};

export default NumericFormatCustom;
