import React from 'react';

import {useField} from 'formik';

import PropTypes from 'prop-types';

import {
  FormControl,
  TextField as RawTextField,
  InputAdornment,
} from '@mui/material';
import {grey} from '@mui/material/colors';

import NumericFormat from '../NumericFormat';

const TextField = (props) => {
  return props?.name ? (
    <TextFieldWithFormik {...props} />
  ) : (
    <BasicTextField {...props} />
  );
};

const TextFieldWithFormik = (props) => {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : '';

  return (
    <BasicTextField
      {...field}
      {...props}
      value={props?.value ?? field?.value ?? ''}
      helperText={errorText}
      error={!!errorText}
    />
  );
};

const BasicTextField = ({
  sx,
  disabled,
  startAdornment,
  endAdornment,
  type,
  readOnly,
  maxNumber,
  ...props
}) => {
  const isNumber = type === 'number';

  return (
    <FormControl sx={{flex: 1, width: '100%'}}>
      <RawTextField
        {...props}
        size='medium'
        sx={{
          backgroundColor: !disabled ? '#fff' : grey[50],
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: grey[300],
          },
          '.MuiFormLabel-root': {
            zIndex: 0,
          },
          '.MuiFormHelperText-root': {
            ml: 0,
          },
          ...sx,
        }}
        disabled={disabled}
        InputProps={{
          ...(isNumber ? {inputComponent: NumericFormat} : {}),
          readOnly: readOnly,
          inputProps: {maxnumber: maxNumber},
          startAdornment: startAdornment ? (
            <InputAdornment position='start'>{startAdornment}</InputAdornment>
          ) : null,
          endAdornment: endAdornment ? (
            <InputAdornment position='end'>{endAdornment}</InputAdornment>
          ) : null,
        }}
      />
    </FormControl>
  );
};

export default TextField;

TextField.propTypes = {
  name: PropTypes.string,
};

BasicTextField.propTypes = {
  sx: PropTypes.object,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  readOnly: PropTypes.bool,
  maxNumber: PropTypes.number,
  endAdornment: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  startAdornment: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

TextFieldWithFormik.propTypes = {
  value: PropTypes.string,
};
