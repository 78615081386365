import {
  CREATE_EVIDENCE,
  DETAIL_HISTORY,
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  READ_HISTORY,
  SHOW_MESSAGE,
} from 'shared/constants/ActionTypes';
import jwtAxios from '@crema/services/auth/jwt-auth';
import {INVOICE, INVOICE_EVIDENCE} from 'shared/constants/ApiUrl';
import {createRouteParams} from 'utils';

export const onGetHistoryData = (params) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    return jwtAxios
      .get(
        `${INVOICE}?${createRouteParams({
          page: 1,
          limit: 99,
          sortOrder: 'created_at',
          ...(params ? params : {}),
        })}`,
      )
      .then(({data, status}) => {
        const apiStatusRes = (data?.status ?? 0) === 1;

        if (status === 200 && apiStatusRes) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: READ_HISTORY, payload: data.data});

          return data;
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: data?.message ?? 'Something went wrong, Please try again!',
          });

          return false;
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload:
            error?.response?.data?.message ||
            'Something went wrong, Please try again!',
        });

        return false;
      });
  };
};

export const onGetHistoryDetailData = (params) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    return jwtAxios
      .get(
        `${INVOICE_EVIDENCE}?${createRouteParams({
          page: 1,
          limit: 99,
          sortOrder: 'created_at',
          sortBy: 'desc',
          ...(params ? params : {}),
        })}`,
      )
      .then(({data, status}) => {
        const apiStatusRes = (data?.status ?? 0) === 1;

        if (status === 200 && apiStatusRes) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: DETAIL_HISTORY, payload: data.data});

          return data;
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: data?.message ?? 'Something went wrong, Please try again!',
          });

          return false;
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload:
            error?.response?.data?.message ||
            'Something went wrong, Please try again!',
        });

        return false;
      });
  };
};

export const onPostHistoryEvidenceData = (params) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    return jwtAxios
      .post(`${INVOICE_EVIDENCE}`, params, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(({data, status}) => {
        const apiStatusRes = (data?.status ?? 0) === 1;

        if (status === 200 && apiStatusRes) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: CREATE_EVIDENCE});
          dispatch({
            type: SHOW_MESSAGE,
            payload: data.message,
          });

          return data;
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: data?.message ?? 'Something went wrong, Please try again!',
          });

          return false;
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload:
            error?.response?.data?.message ||
            'Something went wrong, Please try again!',
        });

        return false;
      });
  };
};
